import { AY } from "@rampjs/schema";

/**
 * Functions to load a script file
 *
 * @param {string} src script URL
 * @returns {Promise<void>} Promise that resolves when script is loaded
 */
const loadScript = (src: string) => {
  return new Promise<void>(function (resolve, reject) {
    const timeout = setTimeout(
      reject,
      10000,
      `Timeout exceeded. Could not load ${src}`,
    );
    const s = document.createElement("script");
    s.type = "text/javascript";
    s.src = src;
    s.async = true;
    s.onerror = function (err) {
      clearTimeout(timeout);
      reject(err);
    };
    s.onload = function () {
      clearTimeout(timeout);
      resolve();
    };
    const t = document.getElementsByTagName("script")[0];
    if (t && t.parentElement) {
      t.parentElement.insertBefore(s, t);
    } else {
      reject(new Error("Failed to find script tag."));
    }
  });
};

export const init = (
  ayConfig: AY.PageOptions,
  targeting: { [key: string]: string | string[] },
) => {
  const googletag = (window.googletag = window.googletag || { cmd: [] });

  const { entity_id: entityId, pingbackBaseUrl } = ayConfig;
  const ayScript = `https://${entityId}.ay.delivery/manager/${entityId}`;

  // load ay script (async)
  loadScript(ayScript).catch((error) => console.error("Error loading ", error));

  // send KVP to GAM
  googletag.cmd.push(() => {
    for (const [key, value] of Object.entries(targeting)) {
      googletag.pubads().setTargeting(key, value);
    }
  });

  // fire pingback URL
  googletag.cmd.push(() => {
    googletag.pubads().addEventListener("slotRenderEnded", (event) => {
      const pingback = new URL(pingbackBaseUrl);
      const {
        advertiserId,
        campaignId,
        creativeId,
        isEmpty,
        lineItemId,
        size,
        slot,
      } = event;
      const adUnitName = slot.getAdUnitPath();

      pingback.searchParams.append("event_type", "ay_impression");
      pingback.searchParams.append("adUnitName", adUnitName);
      pingback.searchParams.append("isEmpty", isEmpty.toString());

      if (advertiserId) {
        pingback.searchParams.append("advertiserId", advertiserId.toString());
      }
      if (campaignId) {
        pingback.searchParams.append("campaignId", campaignId.toString());
      }
      if (creativeId) {
        pingback.searchParams.append("creativeId", creativeId.toString());
      }
      if (lineItemId) {
        pingback.searchParams.append("lineItemId", lineItemId.toString());
      }
      if (size) {
        pingback.searchParams.append("size", size.toString());
      }

      // Send the pingback asynchronously.
      fetch(pingback, { keepalive: true }).catch((error) => {
        console.error("Error sending pingback:", error);
      });
    });
  });
};
